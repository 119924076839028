import {
  faCheckSquare,
  faSquare,
  faExternalLinkAlt,
  faCircleHalfStroke,
  faMoon,
  faSun,
  faGear,
  faWrench,
  faRightFromBracket,
  faChalkboard,
  faChalkboardUser,
  faUserPen,
  faFolder
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { logout, openResetPasswordModal } from '../../../redux/actions';
import { radioLocation } from '../../../../config/env.json';
import { openSignoutModal } from '../../../redux/actions';
import { updateMyTheme } from '../../../redux/settings/actions';
import { Link } from '../../helpers';
import { type ThemeProps, Themes } from '../../settings/theme';
import { User } from '../../../redux/prop-types';
import SupporterBadge from '../../../assets/icons/supporter-badge';
import { Switch, styled } from '@mui/material';

export interface NavLinksProps extends Pick<ThemeProps, 'toggleNightMode'> {
  displayMenu: boolean;
  showMenu: () => void;
  hideMenu: () => void;
  user?: User;
  menuButtonRef: React.RefObject<HTMLButtonElement>;
  logout: () => void;
  openResetPasswordModal: () => void;
}

// const mapDispatchToProps = {
//   toggleNightMode: (theme: Themes) => updateMyTheme({ theme }),
//   openSignoutModal
// };

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      openResetPasswordModal: () => openResetPasswordModal(),
      toggleNightMode: (theme: Themes) => updateMyTheme({ theme }),
      logout: () => logout()
    },
    dispatch
  );

const toggleTheme = (
  currentTheme = Themes.Default,
  toggleNightMode: typeof updateMyTheme
) => {
  toggleNightMode(
    currentTheme === Themes.Night ? Themes.Default : Themes.Night
  );
};

function NavLinks({
  menuButtonRef,
  logout,
  hideMenu,
  displayMenu,
  toggleNightMode,
  user,
  openResetPasswordModal
}: NavLinksProps) {
  const { t } = useTranslation();
  const {
    username: currentUserName,
    theme: currentUserTheme,
    role: userRole
  } = user || {};

  // the accessibility tree just needs a little more time to pick up the change.
  // This function allows us to set aria-expanded to false and then delay just a bit before setting focus on the button
  const closeAndFocus = () => {
    menuButtonRef.current?.classList.add('force-show');
    hideMenu();
    setTimeout(() => {
      menuButtonRef.current?.focus();
      menuButtonRef.current?.classList.remove('force-show');
    }, 100);
  };

  const handleMenuKeyDown = (
    event: React.KeyboardEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      closeAndFocus();
    }
  };

  const handleSignOutClick = (): void => {
    logout();
    hideMenu();
  };

  const handleResetPasswordClick = (): void => {
    console.log('reset password');
    openResetPasswordModal();
    hideMenu();
  };

  const isStaff = () =>
    userRole === 'admin' || userRole === 'manager' || userRole === 'instructor';

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 44,
    height: 24,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 22
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(12px)'
      }
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(20px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#57a9b1'
        }
      }
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 20,
      height: 20,
      borderRadius: 12,
      transition: theme.transitions.create(['width'], {
        duration: 300
      })
    },
    '& .MuiSwitch-track': {
      borderRadius: 24 / 2,
      opacity: 1,
      backgroundColor: '#3B4B4E',
      boxSizing: 'border-box'
    }
  }));

  return (
    <ul
      aria-labelledby='toggle-button-nav'
      data-playwright-test-label='header-menu'
      className={`nav-list${displayMenu ? ' display-menu' : ''}`}
      id='nav-links-list'
    >
      <li key='learn'>
        <Link className='nav-link' onKeyDown={handleMenuKeyDown} to='/learn'>
          <span>
            <FontAwesomeIcon icon={faChalkboardUser} />
            Curriculum
          </span>
        </Link>
      </li>
      {currentUserName && (
        <>
          {isStaff() && (
            <li key='admin'>
              <Link
                className='nav-link'
                onKeyDown={handleMenuKeyDown}
                sameTab={false}
                to={`/admin`}
              >
                <span>
                  <FontAwesomeIcon icon={faWrench} />
                  Admin Area
                </span>
              </Link>
            </li>
          )}
          <li key='file-browser'>
            <Link
              className='nav-link'
              to='/file-browser'
              onKeyDown={handleMenuKeyDown}
            >
              <span>
                <FontAwesomeIcon icon={faFolder} />
                File Browser
              </span>
            </Link>
          </li>
          <li key='settings'>
            <button className='nav-link' onClick={handleResetPasswordClick}>
              <span>
                <FontAwesomeIcon icon={faUserPen} />
                Change Password
              </span>
            </button>
          </li>
        </>
      )}

      <li className='nav-line' key='theme'>
        <button
          {...(!currentUserName && { 'aria-describedby': 'theme-sign-in' })}
          aria-disabled={!currentUserName}
          aria-pressed={currentUserTheme === Themes.Night ? 'true' : 'false'}
          className={
            'nav-link nav-link-flex' +
            (!currentUserName ? ' nav-link-header' : '')
          }
          onClick={() => {
            if (currentUserName) {
              toggleTheme(currentUserTheme, toggleNightMode);
            }
          }}
        >
          {currentUserName ? (
            <>
              <span>
                <FontAwesomeIcon icon={faCircleHalfStroke} />
                Toggle Theme
              </span>
              <AntSwitch checked={currentUserTheme === Themes.Night} />
            </>
          ) : (
            <Fragment key='night-mode'>
              <span className='sr-only'>{t('settings.labels.night-mode')}</span>
              <span
                aria-hidden='true'
                className='nav-link-dull'
                id='theme-sign-in'
              >
                {t('misc.change-theme')}
              </span>
            </Fragment>
          )}
        </button>
      </li>
      {currentUserName && (
        <li className='nav-line' key='sign-out'>
          <button
            className='nav-link nav-link-signout'
            data-value='sign-out-button'
            onClick={handleSignOutClick}
          >
            <span>
              <FontAwesomeIcon icon={faRightFromBracket} />
              Sign Out
            </span>
          </button>
        </li>
      )}
    </ul>
  );
}

NavLinks.displayName = 'NavLinks';

export default connect(null, mapDispatchToProps)(withTranslation()(NavLinks));
